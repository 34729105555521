<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-station-data") }}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->

<!--        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>-->
      </div>
    </div>
    <div class="contents liveRegister">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="title">{{ $t("event-data-site-name") }}</option>
            <option value="address">{{ $t("event-data-site-addr") }}</option>
            <option value="viewId">{{ $t("event-data-site-num") }}</option>
            <option value="departmentName">{{ $t("event-data-site-dept") }}</option>
            <option value="managerName">{{ $t("event-data-site-manager") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="buttonWrap" style="margin-left: 20px;margin-top: 0px;display: flex;">
            <button class="point medium" @click="search" style="width: 120px; margin-left: 0px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
          </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }} <span class="blue">{{ total }}</span>{{ $t("data-case-ea") }}
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
        <table style="table-layout: fixed">
          <tr>
            <th>No</th>
            <th>{{ $t("station-data-stationName") }}</th>
            <th>{{ $t("station-data-tag") }}</th>
            <th>{{ $t("station-data-droneModel") }}</th>
            <th>{{ $t("station-data-droneName") }}</th>
            <th>{{ $t("station-data-managerSite") }}</th>
            <th>{{ $t("station-data-station-managerUser") }}</th>
            <th>{{ $t("station-data-drone-managerUser") }}</th>
            <th>{{ $t("station-data-linkDate") }}</th>
          </tr>
          <colgroup>
            <col style="width: 5%;"/>
            <col style="width: 5%;"/>
            <col style="width: 8%;"/>
            <col style="width: 15%;"/>
            <col style="width: 15%;"/>
            <col style="width: 20%;"/>
            <col style="width: 7%;"/>
            <col style="width: 7%;"/>
            <col style="width: 15%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="10" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(item, idx) in stationDeviceList" :key="'stationlist_'+idx" @click="stationDetail(item.device.stationDeviceId)" class="station-manager-list">
            <td>{{total - (pageSize * pageNumber) - idx }}</td>
            <td>{{ item.device.deviceName }}</td>
            <td>{{ item.device.managerData.tag == null ? item.device.managerData.tag : '' }}</td>
            <td>{{ item.children.modelName }}</td>
            <td>{{ item.children.deviceName }}</td>
            <td>{{ item.device.managerData.eventTitle }}</td>
            <td>{{ item.device.managerData.userName }}</td>
            <td>{{ item.children.managerData.userName }}</td>
            <td>{{ moment(Date(item.device.boundTime)).format("YYYY-MM-DD hh:mm:ss")  }} </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "pageNumber + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
table tr.station-manager-list:hover{cursor: pointer;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import {fetchStationDeviceDockList} from "@/api/station/stationDevice";
import {mapState} from "vuex";

export default {
  components: { Datepicker },
  name: "StationList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      stationDeviceList: [],
      pageNumber: 0,
      pageSize: 10,
      total: 0,
      keywordType: "all",
      keyword: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 4, sub: 0 });
    this.preset();
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  computed: {
    ...mapState({
      accountId: state => state.store.accountId,
    }),
  },
  methods: {
    preset(){
      var localStorageData = localStorage.getItem("event_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.statuses = searchData.statuses;
        this.pageNumber = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("event_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },

    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "stationRegister", query: { eventId: id } });
    },
    reset() {
      this.pageNumber = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
      this.statuses = null;
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }

      this.pageNumber = 0;
    },
    getStationList() {
      let params = {
        page_page: this.pageNumber,
        page_size: this.pageSize,
        keywordType: this.keywordType,
        keyword: this.keyword,
        startDate: this.startYmd,
        endDate: this.endYmd,
      };
      fetchStationDeviceDockList(params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationDeviceList = res.data.data.content;
          console.log(this.stationDeviceList)
          this.total = res.data.data.totalElements;
        }
      }).catch((err) => {
        console.log("stationList")
        console.log("err")
        console.log(err)
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val - 1;
      this.getEventList();
    },
    handleRefresh() {
      this.reset();//변수 초기화
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.getStationList();
    },
    stationDetail(stationDeviceId){
      stationDeviceId;
      this.$router.push({ name: "stationRegister", params: { accountId : this.accountId, stationDeviceId: stationDeviceId }})

    }
  },
};
</script>
